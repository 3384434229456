<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<div class="performance__content">
					<h2 class="performance__title">Total <br> Revenue</h2>
					<small class="performance__subtitle text-light">in Millions</small>
			</div>
			<div class="performance__graphic">
				<number-block :numbers="totalRevenue" />
			</div>
		</div>
	</div>
</template>

<script>
import NumberBlock from '@/components/NumberBlock'

export default {
	name: 'TotalRevenue',
	components: {
		NumberBlock,
	},
	data: () => ({
		totalRevenue: [
			{
				title: '2020',
				value: 1887,
				options: {
					prefix: '$',
				},
			},
			{
				title: '2019',
				value: 1766,
				options: {
					prefix: '$',
				},
			},
			{
				title: '2018',
				value: 1784,
				options: {
					prefix: '$',
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
