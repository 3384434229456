<template>
	<div class="number-block">
		<!-- <div class="number-block__intro" v-html="intro">
		</div> -->

		<div class="number-block__grid">
			<div class="number-block__item" :key="key" v-for="(number, key) in numbers">
				<div class="number-block__item-label" v-html="number.title"></div>
				<div class="number-block__item-number"><counter :number="number.value" :options="number.options" /></div>
				<div class="number-block__item-unit" v-html="number.unit"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Counter from '@/components/Counter'

export default {
	name: 'NumberBlock',
	components: {
		Counter,
	},
	props: {
		intro: String,
		numbers: Array,
	},
}
</script>

<style scoped lang="scss">
$color-blue: #0096D6;
$color-yellow: #E3E431;

.number-block {
	$block: &;
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex: 1 0 auto;
	// height: 100vh;
	// padding-left: 200px;
	// margin-left: -200px;

	.mobile & {
		@include gutter('margin-top', 2);
	}

	@include media-query-min-width('tablet') {
		zoom: 70%;
		flex-direction: row;
	}

	@include media-query-min-width('desktop') {
		zoom: 100%;
	}


	&__intro {
		@include gutter('margin-left', 6);
		color: color_('background', 'dark');
		font-family: $font-family-secondary;
		font-size: 100px;
		font-weight: bold;
		line-height: 0.8;
		max-width: 500px;
		text-transform: uppercase;
		white-space: pre-wrap;

		.mobile & {
			font-size: 50px;
		}
	}

	&__grid {
		// display: grid;
		// grid-auto-rows: 1fr 1fr;
		width: 100%;

		@include media-query-min-width('tablet') {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-auto-rows: 1fr 1fr;
		}

		.mobile & {
			grid-template-columns: 1fr 1fr;
			grid-auto-rows: 1fr auto;
		}
	}

	&__item {
		$item: &;
		@include gutter('padding', 1);
		// align-self: center;
		align-items: center;
		border-bottom: 2px solid color_('border');
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-query-min-width('tablet') {
			@include gutter('padding', 2);
			border-left: 2px solid color_('border');
			border-bottom: 0;
		}

		#{$block}--yellow & {
			border-color: white;
		}

		.mobile & {
			@include gutter('padding', 1);
			border-left: 0;

			&:first-of-type {
				border-bottom: 2px solid;
				// border-color: color_('text', 'xlight');
			}
		}

		&:first-of-type {
			border-left: 0;
			// grid-row: 1 / span 2;

			@include media-query-min-width('tablet') {
				grid-row: 1 / span 2;
			}

			.mobile & {
				grid-row: 1;
				grid-column: 1 / span 2;
			}

			#{$item}-label {
				// @include fontSize(40px);
				@include media-query-min-width('tablet') {
					transform: translateY(25%);
				}

				// @include media-query-min-width('tablet') {
				// 	@include fontSize(120px);
				// }

				.mobile & {
					font-size: 20px;
				}
			}

			#{$item}-number {
				@include fontSize(96px);

				@include media-query-min-width('tablet') {
					@include fontSize(192px);
				}

				.mobile & {
					font-size: 100px;
				}
			}

			#{$item}-unit {
				@include fontSize(60px);

				.mobile & {
					font-size: 30px;
				}
			}
		}

		&:nth-of-type(2) {
			#{$block}--yellow & {
				border-color: color_('text', 'xlight');
			}

			border-bottom: 2px solid color_('border');

			.mobile & {
				border-bottom: 0;
				border-right: 2px solid;
				// border-color: color_('text', 'xlight');
			}
		}

		&-label {
			@include fontSize(24px);
			color: color_('text','light');
			font-weight: 200;

			#{$block}--yellow & {
				color: white;
			}

			.mobile & {
				font-size: 10px;
			}
		}

		&-number {
			@include fontSize(56px);
			color: $color-blue;
			font-family: $font-family-secondary;
			font-weight: bold;
			line-height: 1;
			letter-spacing: -0.5px;

			@include media-query-min-width('tablet') {
				@include fontSize(64px);
			}

			#{$block}--yellow & {
				color: $color-yellow;
			}

			.mobile & {
				font-size: 35px;
			}
		}

		&-unit {
			@include fontSize(20px);
			color: color_('text');
			font-family: $font-family-secondary;
			text-transform: uppercase;

			@include media-query-min-width('tablet') {
				@include fontSize(24px);
			}

			#{$block}--yellow & {
				color: white;
			}

			.mobile & {
				font-size: 12px;
			}
		}
	}
}
</style>
